'use client'

import { Button, Grid2 as Grid, Typography } from '@mui/material'

import { useRouter } from 'next/navigation'

import { useTranslations } from 'next-intl'

import CustomErrorRenderer from 'components/common/CustomErrorRenderer'

import Routes from 'types/enums/routes'
import { StaticAssets } from 'types/enums/staticAssets'

const Custom404 = () => {
  const t = useTranslations()
  const router = useRouter()

  return (
    <CustomErrorRenderer>
      <Grid container direction="column" textAlign="center" rowSpacing={1} size={12}>
        <Grid>
          <Typography variant="h3">{t('notFound.title')}</Typography>
        </Grid>
        <Grid>
          <Typography variant="body1" color="text.secondary">
            {t('notFound.description')}
          </Typography>
        </Grid>
      </Grid>
      <Grid display="block" width="90%">
        <img src={StaticAssets.NOT_FOUND} alt="not-found" />
      </Grid>
      <Grid textAlign="center">
        <Button size="large" variant="contained" onClick={() => router.push(Routes.ROOT)}>
          {t('goToHome')}
        </Button>
      </Grid>
    </CustomErrorRenderer>
  )
}

export default Custom404
